import React, { useState } from 'react';
import {
    IniciosBg,
    InicioBtnWrapper,
    InicioContent,
    InicioH1,
    InicioP,
    IniciosContainer,
    VideoBg
} from "./InicioElements";
import Video from '../../videos/video.mp4';
import { Button } from '../ButtonElements';
import { LINK_WHATSAPP } from '../../settings/config';

const Inicio = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    return (
        <IniciosContainer id='inicio'>
            <IniciosBg>
                <VideoBg autoPlay loop muted src={Video} />
            </IniciosBg>
            <InicioContent>
                <InicioH1>{'Uma plataforma completa para o seu Negócio'}</InicioH1>
                <InicioP>{'Notificações via WhatsApp, envio marketing, gestão de lucro e muito mais...'}</InicioP>
                <InicioP>{'Automatize os seus processos.'}</InicioP>
                <InicioP style={{ fontSize: '20px', maxWidth: '800px' }}>{'280+ Clientes | 20+ Revendedores Autorizados | 80 mil+ Clientes Cadastrados'}</InicioP>
                <InicioBtnWrapper>
                    <Button href={LINK_WHATSAPP} target='_blank' onMouseEnter={onHover} onMouseLeave={onHover} dark={1}>{'Fazer Orçamento'}</Button>
                </InicioBtnWrapper>
            </InicioContent>
        </IniciosContainer >
    )
}

export default Inicio;