import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Inicio from '../components/Inicio';
import { apresentacaoData, recursosData, planosData, integracaoData } from '../components/SectionBase/Data';
import SectionBase from '../components/SectionBase';
import Footer from '../components/Footer';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Home = () => {
    const location = useLocation();
    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", title: 'Pub_BitSafira - ' + location.pathname });
    }, [location]);

    return (
        <>
            <Sidebar open={open} toggle={toggle} />
            <Navbar toggle={toggle} />
            <Inicio />
            <SectionBase {...apresentacaoData} />
            <SectionBase {...recursosData} />
            <SectionBase {...planosData} />
            <SectionBase {...integracaoData} />
            <Footer />
        </>
    )
}

export default Home;