import { COLOR_THEME, LINK_WHATSAPP } from "../../settings/config";
import { Button } from "../ButtonElements";
import { Card, CardContent, CardH1, CardH2, CardIcon, CardP } from "./SectionBaseElements"

export const CardApresentacao = (props: any) => {
    return (
        <Card>
            <CardIcon>{props.icon}</CardIcon>
            <CardContent>
                <CardH2 lighttext={props.lighttext ? 1 : 0}>{props.title}</CardH2>
                <CardP lighttext={props.lighttext ? 1 : 0}>{props.description}</CardP>
            </CardContent>
        </Card>
    )
}

export const CardPlano = (props: any) => {
    return (
        <Card style={{ transform: 'none', cursor: 'default' }}>
            <CardContent style={{ border: '2px solid #fff', borderRadius: '5px', textAlign: 'center' }}>
                <CardH1 style={{ backgroundColor: '#fff', color: COLOR_THEME }}>{props.title}</CardH1>
                <CardP lighttext={1} style={{ fontSize: '0.9rem', padding: '10px', lineHeight: '25px', maxWidth: '300px' }}>{props.description}</CardP>
                <Button href={LINK_WHATSAPP} target="_black" style={{ margin: '10px 20px' }}>{'Fazer Orçamento'}</Button>
            </CardContent>
        </Card>
    )
}


export function getRecursos() {
    const recursos = [
        'Gestão de Clientes',
        'Gestão de Serviços',
        'Gestão de Renovações',
        'Gestão de Lucros',
        'Gestão de Listas de Transmissões',
        'Gestão de Templates de Mensagens',
        'Gestão de Envios',
        'Conexão Direta com seu Whatsapp',
        'E muito mais...'
    ]

    const listaRecursos = recursos.map((recurso, index) => (
        <div key={index} style={{ padding: 3 }}>
            <span style={{ fontWeight: 'bold', color: COLOR_THEME, fontSize: '1.2rem', marginRight: 10 }}>&#x2713;</span>
            {recurso}
        </div>
    ));

    return listaRecursos;
}